import watch from '../images/appleWatch2.jpg';
import card from '../images/contestCard.jpg';

import React, { CSSProperties } from 'react';
interface Styles {
  mainContainer: CSSProperties;
  contentWrapper: CSSProperties;
  header: CSSProperties;
  subHeader: CSSProperties;
  title: CSSProperties;
  prizesContainer: CSSProperties;
  imageContainer: CSSProperties;
  image: CSSProperties;
  orText: CSSProperties;
  giftCardContainer: CSSProperties;
  giftCardAmount: CSSProperties;
  rulesContainer: CSSProperties;
  ruleText: CSSProperties;
  deadline: CSSProperties;
}
const getStyles = (windowWidth: number): Styles => ({
  mainContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  contentWrapper: {
    width: '100%',
    backgroundColor: '#faf9f8',
    padding: windowWidth < 768 ? 20 : 40,
    margin: 'auto',
  },
  header: {
    textAlign: 'center',
    marginBottom: windowWidth < 768 ? 24 : 48,
  },
  subHeader: {
    fontSize: windowWidth < 768 ? '16px' : '20px',
    color: 'black',
    fontWeight: 'bold',
    marginBottom: 16,
    padding: '0 20px',
  },
  title: {
    fontSize: windowWidth < 768 ? '48px' : '72px',
    fontWeight: 700,
    marginBottom: windowWidth < 768 ? 24 : 32,
    color: 'black',
  },
  prizesContainer: {
    display: 'flex',
    flexDirection: windowWidth < 768 ? 'column' : 'row',
    alignItems: 'start',
    justifyContent: 'center',
    gap: windowWidth < 768 ? '24px' : '48px',
    marginBottom: windowWidth < 768 ? 32 : 64,
    padding: '20px 0',
  },
  imageContainer: {
    width: windowWidth < 768 ? 240 : 320,
    height: windowWidth < 768 ? 240 : 320,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    transition: 'transform 0.3s ease',
    cursor: 'pointer',
  },
  image: {
    maxWidth: '100%',
    height: 'auto',
    objectFit: 'contain',
    borderRadius: 8,
  },
  orText: {
    fontSize: windowWidth < 768 ? '32px' : '48px',
    fontWeight: 700,
    margin: windowWidth < 768 ? '8px 0' : '0 32px',
    color: 'black',
    alignContent: 'start',
  },
  giftCardContainer: {
    position: 'relative',
    width: '100%',
    height: '100%',
  },
  giftCardAmount: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    fontSize: windowWidth < 768 ? '32px' : '48px',
    fontWeight: 700,
    color: '#fff',
    backgroundColor: 'rgba(0, 0, 0, 0.7)',
    padding: '8px 16px',
    borderRadius: 8,
  },
  rulesContainer: {
    textAlign: 'center',
    maxWidth: windowWidth < 768 ? '100%' : '800px',
    margin: '0 auto',
    padding: windowWidth < 768 ? '0 16px' : '0 32px',
  },
  ruleText: {
    fontSize: windowWidth < 768 ? '16px' : '20px',
    marginBottom: 16,
    lineHeight: 1.2,
    color: 'black',
    justifyContent: 'start',
    textAlign: 'left',
    fontWeight: 600,
    fontFamily: 'Poppins',
  },
  deadline: {
    fontSize: windowWidth < 768 ? '18px' : '24px',
    fontWeight: 700,
    marginTop: 24,
    color: '#333',
    padding: '16px',
    backgroundColor: '#F8F8F8',
    borderRadius: 8,
    display: 'inline-block',
  },
});
const FirstSplashScreen: React.FC = () => {
  const [windowWidth, setWindowWidth] = React.useState(window.innerWidth);
  React.useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);
  const styles = getStyles(windowWidth);
  return (
    <div style={styles.mainContainer}>
      <div style={styles.contentWrapper}>
        <div style={styles.header}>
          <span> </span>
          <span> </span>
          <span> </span>
          <p style={styles.subHeader}>
            <span className="light">open only to the</span>
            <span className="bold"> Food Los Angeles</span>
            <span className="light"> sub.</span>
          </p>
          <h1 style={styles.title}>WIN</h1>
        </div>
        <div style={styles.prizesContainer}>
          <div style={styles.imageContainer}>
            <img src={watch} alt="Apple Watch with blue band" style={styles.image} />
          </div>
          <span style={styles.orText}>OR</span>
          <div style={styles.imageContainer}>
            <div style={styles.giftCardContainer}>
              <img src={card} alt="Amazon Gift Cards" style={styles.image} />
            </div>
          </div>
        </div>
        <div style={styles.rulesContainer}>
          <p style={styles.ruleText}>
            Everyone wants to know your favorite menu item from a local LA restaurant. Save your fave at Yolomeal.com
            and you are in it to win it! Save a photo of the menu item and get 4 extra entries! Hurry, contest ends and
            drawing is on 2/22/2025
          </p>
        </div>
      </div>
    </div>
  );
};
export default FirstSplashScreen;