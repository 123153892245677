import React, { useState } from 'react';
import Modal from 'react-modal';

type Props = {
  isOpen: boolean;
  user: any;
  yoloId: any;
  onEditUpvoteCount(count) : void;
  handleModalClose(): void;
  onUpvoteYoloChange(event: React.ChangeEvent<HTMLTextAreaElement>) : any;
};

const customStyles = {
  content: {
    left: 0,
    top: 0,
    width: '100%',
    height: '100%',
    margin: 0,
    padding: 0,
    border: 0,
    background: '#f9c5b4'
  },
};

const EditUpvoteYoloModal: React.FC<Props> = (props) => {
  const [error, setError] = useState('');

  const onEditUpvoteCount = (count)=> {
    props.onEditUpvoteCount(count);
  }

  const closeModel = ()=>{
    props.handleModalClose();
  }


  return (
    <Modal isOpen={props.isOpen} ariaHideApp={false} style={customStyles}>
      <div className="container bg-orange">
        <section>
          <div className="small-container add-yolo-info text-center">
            <div className="yolo-container ">
              <h2 className="futuralt-book mt-4 pt-2 mb-4 text-center f-22"> ENTER NUMBER OF UPVOTE YOU WANT TO ADD FOR THIS ITEM </h2>
              <ul>
                <li className="tell-us">
                  <textarea autoFocus maxLength={300}
                            rows={1}
                            onChange={props.onUpvoteYoloChange}
                            style={{border: "0px solid #ccc" }}
                  >
                  </textarea>
                </li>
              </ul>
            </div>
            {error !== '' &&
              <p>{error}</p>
            }
            <div className="mt-0 mb-4 d-flex-buttons black justify-content-space-between align-items-center uppercase">
              <h1>
                <button className="next-button" onClick={closeModel}>BACK</button>
              </h1>
              <h1>
                <button className="next-button" onClick={onEditUpvoteCount}>SAVE YOLO</button>
              </h1>
            </div>
          </div>
        </section>
      </div>
    </Modal>
  )
}


export default EditUpvoteYoloModal;